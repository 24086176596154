<template>
    <div class="page-wrapper">
        <div class="constrained">
            <header>
                <div class="left-wrapper-header">
                    <div class="site-logo"><img src="/gec-logo.svg" alt="">
                        <p>Geometric Energy</p>
                    </div>
    
                    <div class="desktop-menu">
                        <a href="https://geometricenergy.ca/">GEC info</a>
                        <a href="https://t.me/GECERC">Telegram</a>
                        <a href="https://twitter.com/geometricenergy">Twitter</a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xa2caade2b5d21216836b81dd2d81bd3743ef7988">Chart</a>
                    </div>
                </div>
                <div class="mobile-menu">
                    <div class="mobile-menu-ham" @click="menuClosed = !menuClosed">
                        <svg v-if="menuClosed" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                              <circle cx="17.5" cy="17.5" r="17" stroke="#1F1F1F"/>
                                                                                                                              <path d="M10 15H25.5" stroke="#A1A1A1"/>
                                                                                                                              <path d="M10 20H25.5" stroke="#A1A1A1"/>
                                                                                                                              </svg>
                        <svg v-if="!menuClosed" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <circle cx="17.5" cy="17.5" r="17" stroke="#1F1F1F"/>
                                                                              <path d="M12 12L22.9602 22.9602" stroke="#A1A1A1"/>
                                                                              <path d="M12.2699 23L23.2301 12.0398" stroke="#A1A1A1"/>
                                                                            </svg>
                    </div>
                    <div class="wrapper-menu" v-if="!menuClosed">
                        <div class="ticker">$GEC</div>
    
                        <a href="https://geometricenergy.ca/">GEC info</a>
                        <a href="https://t.me/GECERC">Telegram</a>
                        <a href="https://twitter.com/geometricenergy">Twitter</a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xa2caade2b5d21216836b81dd2d81bd3743ef7988">Chart</a>
                    </div>
                </div>
                <div class="ticker">$GEC</div>
            </header>
            <div class="stack ">
    
    
                <div class="header-section">
                    <div class="header-graphic"><img src="hero.svg" alt=""></div>
                    <div class="header-cta-wrapper">
                        <div class="header-cta-desc">
                            <p>Geometric Energy Corporation (GEC) is a state-of-the-art technology company committed to facilitating positive social change of humanity by commercializing next-generation technologies within the sustainable energy, space, and
                                computing industries.</p>
                        </div>
                        <div class="header-cta-buttons">
                            <div class="cta-button-row">
                                <a href="https://geometricenergy.ca/">
                                    <div class="button-big white-button">$GEC <img src="button-arrow.svg" alt=""></div>
                                </a>
                                <a href="https://t.me/GECERC">
                                    <div class="button-big">Community</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="news-section">
                    <div class="news-info-header">
                        <div class="news-teaser">News</div>
                        <div class="news-latest">
                            <p><b>GEC Token</b> now live to trade on <b>MEXC</b>. Trade, buy GEC at ease.</p>
                        </div>
                        <a href="">
                            <div class="button-small">Follow the news <img src="button-small-arrow.svg" alt=""></div>
                        </a>
                    </div>
                    <div class="news-info-header-mobile">
    
                        <a href="">
                            <div class="button-small">
                                <div class="news-teaser">News</div>
                                Listing Announcement <img src="button-small-arrow.svg" alt=""></div>
                        </a>
                    </div>
                    <div class="news-title">
                        Advanced Ionics for Sustainable Energy for Mobile Devices, Electric Vehicles and Power Supplies
                    </div>
    
                    <div class="news-details-row-wrapper">
                        <div class="news-detail-company">
                            <div class="news-detail-company-projects">
                                <h4>Geometric Space</h4>
                                <div class="button-stack">
                                    <a href="https://en.wikipedia.org/wiki/Doge-1">
                                        <div class="button-small">DOGE-1</div>
                                    </a>
                                    <a href="https://www.prnewswire.com/news-releases/geometric-energy-to-launch-aboard-falcon-9-rideshare-mission-301260498.html">
                                        <div class="button-small">Geometric-1</div>
                                    </a>
                                    <a href="https://apps.fcc.gov/els/GetAtt.html?id=316441&x=.">
                                        <div class="button-small">Geometric-2</div>
                                    </a>
                                </div>
                            </div>
                            <div class="news-detail-desc">
                                <h4>Geometric Mission</h4>
                                <p>GEC's space exploration achievements include the successful Geometric 1 mission and the anticipated launches of Doge 1 and Geometric 2.</p>
                            </div>
                        </div>
                        <div class="news-detail-token-row">
    
                            <div class="news-detail-stack">
                                <div class="news-detail-links">
                                    <a href="https://app.uniswap.org/swap?outputCurrency=0x3001f57F8308b189EB412a64322Aad5eF9951290"><img src="uniswap.svg" alt=""></a>
                                    <a href="https://www.mexc.com/exchange/GEC_USDT?_from=search_spot_trade" ><img src="mexc.svg" alt=""></a>
                                    <a href="https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=GEC_USDT" ><img src="bitmart.svg" alt=""></a>
                                </div>
                                <div class="news-detail-desc">
                                    <h4>Trade $GEC</h4>
                                    <p>$GEC is now available for trading on centralized exchanges like MEXC and Bitmart, as well as on the decentralized platform Uniswap.</p>
                                </div>
    
                            </div>
    
                            <div class="news-detail-stack">
                                <div class="news-detail-links">
                                    <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xa2caade2b5d21216836b81dd2d81bd3743ef7988"><img src="dextools.svg" alt=""></a>
                                    <a href="https://www.coingecko.com/en/coins/geometric-energy-corporation"><img src="coingecko.svg" alt=""></a>
                                    <a href="coinmarketcap.com/currencies/gec/"><img src="coinmarketcap.svg" alt=""></a>
                                </div>
                                <div class="news-detail-desc">
                                    <h4>Track GEC Token</h4>
                                    <p>To stay updated on the latest $GEC price, visit platforms such as CoinGecko, CoinMarketCap, and Dextools. Don't forget to add $GEC to your watchlist for easy monitoring.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tokenomics-section">
                    <div class="tokenomics-info-header">
                        <div class="tokenomics-desc">
                            <p><b>GEC Tokenomics</b>, Total supply 10,000,000 GEC</p>
                            <a target="_blank" href="https://etherscan.io/address/0x3001f57f8308b189eb412a64322aad5ef9951290">
                                <p style="cursor:pointer;" @click="copyToClipboard('0x3001f57F8308b189EB412a64322Aad5eF9951290')">0x3001f57F8308b189EB412a64322Aad5eF9951290</p>
                            </a>
                        </div>
                        <div class="token-buttons">
                            <div class="button-small">1% Buy</div>
                            <div class="button-small">1% Sell</div>
                        </div>
                    </div>
                    <div class="tokenomics-info-graphic">
                        <div class="tokenomics-header">
                            <p><b>GEC is pioneering cislunar communications</b> for cryptocurrency, marking the beginning of an interplanetary economy between Earth and the Moon.</p>
                        </div>
                        <div class="tokenomics-graphic">
                            <img src="satellite-graphic.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <p>© 2023 Geometric Energy GEC, All rights reserved.</p>
                <div class="social-row">
                    <a href="https://twitter.com/geometricenergy"><img src="twitter-gec.svg" alt=""></a>
                    <a href="https://t.me/GECERC"><img src="telegram-gec.svg" alt=""></a>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({

        menuClosed: true,

    }),
    components: {},
    computed: {

    },
    mounted() {},
    beforeUnmount() {},
    methods: {
        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                console.log('Text copied to clipboard');
                // Optionally, implement a notification to the user
            } catch (err) {
                console.error('Failed to copy: ', err);
                // Optionally, handle the error, like showing an error message
            }
        },


    },
    watch: {

    },
}
</script>
  
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    font-family: 'Montserrat', sans-serif;
    color: white;
    transition: all 0.2s ease;
    scrollbar-width: none;
}

header {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    .left-wrapper-header {
        display: flex;
        flex-flow: row;
        .site-logo {
            display: flex;
            flex-flow: row;
            width: max-content;
            p {
                white-space: nowrap;
                font-weight: 500;
                margin: auto;
                margin-left: 0.25rem;
            }
        }
        .desktop-menu {
            margin: auto 1.5rem;
            display: flex;
            flex-flow: row;
            gap: 1.5rem;
            a {
                white-space: nowrap;
            }
        }
    }
    .mobile-menu {
        display: none;
    }
    .ticker {
        display: inline-flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #FFF;
        color: black;
    }
}

.button-big {
    border-radius: 40px;
    border: 1px solid #202020;
    background: #0A0A0A;
    display: inline-flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    font-weight: 500;
    &:hover {
        border: 1px solid #202020;
        background: #181818;
        color: #fffa;
    }
}

.button-small {
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #202020;
    background: #0A0A0A;
    &:hover {
        border-radius: 30px;
        border: 1px solid #A1A1A1;
        background: #0A0A0A;
        color: #fffa;
        img {
            padding-left: 5px;
            margin-right: -5px;
            filter: invert(1);
        }
    }
}

.white-button {
    background-color: white;
    border-color: white;
    color: black;
    &:hover {
        img {
            padding-left: 5px;
            margin-right: -5px;
            filter: invert(1);
        }
        color:white;
    }
}

.header-section {
    .header-graphic {
        display: flex;
        img {
            width: 100%;
        }
    }
    .header-cta-wrapper {
        display: flex;
        flex-flow: wrap;
        border-right: 1px solid #1F1F1F;
        z-index: 2;
        position: relative;
        .header-cta-desc {
            p {
                font-weight: 300;
            }
            padding:2rem 1.5rem;
            border-left: 1px solid #1F1F1F;
            border-bottom: 1px solid #1F1F1F;
            width: 50%;
            position: relative;
            &:before {
                content: '';
                height: 28px;
                width: 1px;
                background: #878787;
                position: absolute;
                bottom: -14px;
                right: -1px;
                z-index: 2;
            }
            &:after {
                content: '';
                height: 1px;
                width: 28px;
                background: #878787;
                position: absolute;
                bottom: 0px;
                right: -14px;
                z-index: 2;
            }
        }
        .header-cta-buttons {
            border-left: 1px solid #1F1F1F;
            border-bottom: 1px solid #1F1F1F;
            width: 50%;
            position: relative;
            display: flex;
            flex-flow: column;
            justify-content: center;
            .cta-button-row {
                display: flex;
                flex-flow: row;
                justify-content: center;
                gap: 1.2rem;
            }
            &:before {
                content: '';
                height: 28px;
                width: 1px;
                background: #878787;
                position: absolute;
                bottom: -14px;
                right: -1px;
                z-index: 2;
            }
            &:after {
                content: '';
                height: 1px;
                width: 28px;
                background: #878787;
                position: absolute;
                bottom: 0px;
                right: -14px;
                z-index: 2;
            }
        }
    }
}

.news-section {
    .news-info-header-mobile {
        display: none;
    }
    .news-info-header {
        padding: 30px 40px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border: 1px solid #1F1F1F;
        background: #0A0A0A;
        border-top: unset;
        .news-teaser {
            border-radius: 27px;
            background: #083A33;
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #0ac7b0;
        }
        .news-latest {
            margin: auto 0px;
            p {
                font-weight: 300;
            }
            b {
                font-weight: 500;
            }
        }
    }
    .news-title {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 100px;
        border: 1px solid #1F1F1F;
        border-top: unset;
        &:before {
            content: '';
            height: 28px;
            width: 1px;
            background: #878787;
            position: absolute;
            bottom: -14px;
            left: -1px;
            z-index: 2;
        }
        &:after {
            content: '';
            height: 1px;
            width: 28px;
            background: #878787;
            position: absolute;
            bottom: 0px;
            left: -14px;
            z-index: 2;
        }
    }
    .news-details-row-wrapper {
        display: flex;
        flex-flow: row;
        border-right: 1px solid #1F1F1F;
        &:before {
            content: '';
            height: 28px;
            width: 1px;
            background: #878787;
            position: absolute;
            bottom: -14px;
            right: -1px;
            z-index: 2;
        }
        &:after {
            content: '';
            height: 1px;
            width: 28px;
            background: #878787;
            position: absolute;
            bottom: 0px;
            right: -14px;
            z-index: 2;
        }
        .news-detail-company {
            max-width: 33.33%;
            padding: 3rem 2rem;
            border: 1px solid #1F1F1F;
            border-top: unset;
            border-right: unset;
            display: flex;
            flex-flow: column;
            gap: 3rem;
            .news-detail-company-projects {
                h4 {
                    color: #A1A1A1;
                    text-align: left;
                    font-family: Montserrat;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .button-stack {
                    margin-top: 1.2rem;
                    display: flex;
                    flex-flow: column;
                    gap: 1rem;
                }
            }
            .news-detail-desc {
                min-height: 124px;
                h4 {
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 0.5rem;
                }
                p {
                    color: #A1A1A1;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
    .news-detail-token-row {
        display: flex;
        flex-flow: row;
        width: 100%;
        .news-detail-stack {
            max-width: 50%;
            width: 100%;
            padding: 3rem 2rem;
            border: 1px solid #1F1F1F;
            border-top: unset;
            border-right: unset;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            gap: 3rem;
            .news-detail-links {
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                gap: 2rem;
                a {
                    margin: auto;
                }
            }
            .news-detail-desc {
                min-height: 124px;
                h4 {
                    color: #FFF;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 0.5rem;
                }
                p {
                    color: #A1A1A1;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

.tokenomics-section {
    .tokenomics-info-header {
        border: 1px solid #1F1F1F;
        background: #0A0A0A;
        border-top: unset;
        padding: 30px 40px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        .tokenomics-desc {
            p {
                font-weight: 300;
            }
            b {
                font-weight: 500;
            }
        }
        .token-buttons {
            display: flex;
            flex-flow: row;
            gap: 1rem;
            margin: auto 0px;
        }
    }
    .tokenomics-info-graphic {
        border: 1px solid #1F1F1F;
        border-top: unset;
        .tokenomics-header {
            padding: 4rem 3rem;
            p {
                color: #FFF;
                text-align: center;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
                width: 600px;
                margin: auto;
            }
            b {
                font-weight: 500;
            }
        }
        .tokenomics-graphic {
            display: flex;
            flex-flow: row;
            width: 100%;
            justify-content: center;
            overflow: hidden;
            position: relative;
            img {
                margin: auto;
                margin-bottom: 0rem;
            }
        }
    }
}

footer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 2rem 0rem;
    p {
        color: #FFF;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
    }
    .social-row {
        display: flex;
        flex-flow: row;
        gap: 1rem;
    }
}

.page-wrapper {
    position: fixed;
    top: 0px;
    display: flex;
    height: 100vh;
    width: 100vw;
    background: black;
    overflow-x: hidden;
    overflow-y: auto;
}

.constrained {
    margin: 0px auto;
    width: 1024px;
    max-width: calc(100vw - 2rem);
}

.pending {
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
    filter: saturate(0);
}


@media screen and (max-width:100vh) {
    header {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 4rem;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        .left-wrapper-header {
            display: flex;
            flex-flow: row;
            .site-logo {
                display: flex;
                flex-flow: row;
                width: max-content;
                p {
                    white-space: nowrap;
                    font-weight: 500;
                    margin: auto;
                    margin-left: 0.25rem;
                }
            }
            .desktop-menu {
                margin: auto 1.5rem;
                display: none;
                flex-flow: row;
                gap: 1.5rem;
                a {
                    white-space: nowrap;
                }
            }
        }
        .mobile-menu {
            position: relative;
            z-index: 10;
            display: flex;
            flex-flow: column;
            justify-content: center;
            height: 100%;
            .wrapper-menu {
                width: 100vw;
                height: calc(100vh - 5rem);
                background: black;
                position: fixed;
                z-index: 5;
                left: 0px;
                top: 6rem;
                flex-flow: column;
                padding: 1rem;
                display: flex;
                .ticker {
                    z-index: 6;
                    position: fixed;
                    top: 4rem;
                    display: flex;
                    width: calc(100% - 2rem);
                }
                a {
                    width: 100%;
                    padding: 0.25rem 0rem;
                    border-bottom: 1px solid white;
                }
            }
            .mobile-menu-ham {
                display: flex;
                flex-flow: column;
                justify-content: center;
            }
        }
        .ticker {
            display: none;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            background: #FFF;
            color: black;
        }
    }
    .button-big {
        border-radius: 40px;
        border: 1px solid #202020;
        background: #0A0A0A;
        display: inline-flex;
        padding: 16px 40px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        font-weight: 500;
        white-space: nowrap;
        &:hover {
            border: 1px solid #202020;
            background: #181818;
            color: #fffa;
        }
    }
    .button-small {
        display: inline-flex;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid #202020;
        background: #0A0A0A;
        white-space: nowrap;
        &:hover {
            border-radius: 30px;
            border: 1px solid #A1A1A1;
            background: #0A0A0A;
            color: #fffa;
            img {
                padding-left: 5px;
                margin-right: -5px;
                filter: invert(1);
            }
        }
    }
    .white-button {
        background-color: white;
        border-color: white;
        color: black;
        &:hover {
            img {
                padding-left: 5px;
                margin-right: -5px;
                filter: invert(1);
            }
            color:white;
        }
    }
    .header-section {
        .header-graphic {
            display: flex;
            img {
                width: 100%;
            }
        }
        .header-cta-wrapper {
            display: flex;
            flex-flow: wrap;
            border-right: 1px solid #1F1F1F;
            z-index: 2;
            position: relative;
            .header-cta-desc {
                p {
                    font-weight: 300;
                }
                padding:2rem 1.5rem;
                border-left: 1px solid #1F1F1F;
                border-bottom: 0px solid #1F1F1F;
                width: 100%;
                position: relative;
                &:before {
                    display: none;
                    content: '';
                    height: 28px;
                    width: 1px;
                    background: #878787;
                    position: absolute;
                    bottom: -14px;
                    right: -1px;
                    z-index: 2;
                }
                &:after {
                    display: none;
                    content: '';
                    height: 1px;
                    width: 28px;
                    background: #878787;
                    position: absolute;
                    bottom: 0px;
                    right: -14px;
                    z-index: 2;
                }
            }
            .header-cta-buttons {
                border-left: 1px solid #1F1F1F;
                border-bottom: 1px solid #1F1F1F;
                width: 100%;
                position: relative;
                padding: 0rem 0rem 2rem;
                display: flex;
                flex-flow: column;
                justify-content: center;
                .cta-button-row {
                    display: flex;
                    flex-flow: row;
                    justify-content: center;
                    gap: 1.2rem;
                }
                &:before {
                    content: '';
                    height: 28px;
                    width: 1px;
                    background: #878787;
                    position: absolute;
                    bottom: -14px;
                    right: -1px;
                    z-index: 2;
                }
                &:after {
                    content: '';
                    height: 1px;
                    width: 28px;
                    background: #878787;
                    position: absolute;
                    bottom: 0px;
                    right: -14px;
                    z-index: 2;
                }
            }
        }
    }
    .news-section {
        .news-info-header-mobile {
            display: flex;
            padding: 30px 40px;
            flex-flow: row;
            justify-content: space-between;
            border: 1px solid #1F1F1F;
            background: #0A0A0A;
            border-top: unset;
            .news-teaser {
                border-radius: 27px;
                background: #083A33;
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #0ac7b0;
                margin-left: -0.5rem;
            }
            .news-latest {
                margin: auto 0px;
                p {
                    display: none;
                    font-weight: 300;
                }
                b {
                    font-weight: 500;
                }
            }
        }
        .news-info-header {
            padding: 30px 40px;
            display: none;
            flex-flow: row;
            justify-content: space-between;
            border: 1px solid #1F1F1F;
            background: #0A0A0A;
            border-top: unset;
            .news-teaser {
                border-radius: 27px;
                background: #083A33;
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #0ac7b0;
            }
            .news-latest {
                margin: auto 0px;
                p {
                    display: none;
                    font-weight: 300;
                }
                b {
                    font-weight: 500;
                }
            }
        }
        .news-title {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 2rem;
            border: 1px solid #1F1F1F;
            border-top: unset;
            &:before {
                display: none;
                content: '';
                height: 28px;
                width: 1px;
                background: #878787;
                position: absolute;
                bottom: -14px;
                left: -1px;
                z-index: 2;
            }
            &:after {
                display: none;
                content: '';
                height: 1px;
                width: 28px;
                background: #878787;
                position: absolute;
                bottom: 0px;
                left: -14px;
                z-index: 2;
            }
        }
        .news-details-row-wrapper {
            display: flex;
            flex-flow: column;
            border-right: 1px solid #1F1F1F;
            &:before {
                content: '';
                height: 28px;
                width: 1px;
                background: #878787;
                position: absolute;
                bottom: -14px;
                right: -1px;
                z-index: 2;
            }
            &:after {
                content: '';
                height: 1px;
                width: 28px;
                background: #878787;
                position: absolute;
                bottom: 0px;
                right: -14px;
                z-index: 2;
            }
            .news-detail-company {
                max-width: 100%;
                padding: 3rem 2rem;
                border: 1px solid #1F1F1F;
                border-top: unset;
                border-right: unset;
                display: flex;
                flex-flow: column;
                gap: 3rem;
                &:before {
                    content: '';
                    height: 28px;
                    width: 1px;
                    background: #878787;
                    position: absolute;
                    bottom: -14px;
                    left: -1px;
                    z-index: 2;
                }
                &:after {
                    content: '';
                    height: 1px;
                    width: 28px;
                    background: #878787;
                    position: absolute;
                    bottom: 0px;
                    left: -14px;
                    z-index: 2;
                }
                .news-detail-company-projects {
                    h4 {
                        color: #A1A1A1;
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    .button-stack {
                        margin-top: 1.2rem;
                        display: flex;
                        flex-flow: wrap;
                        justify-content: center;
                        gap: 1rem;
                    }
                }
                .news-detail-desc {
                    min-height: inherit;
                    text-align: center;
                    h4 {
                        color: #FFF;
                        font-family: Montserrat;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 0.5rem;
                    }
                    p {
                        color: #A1A1A1;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
        .news-detail-token-row {
            display: flex;
            flex-flow: row;
            width: 100%;
            .news-detail-stack {
                max-width: 50%;
                width: 100%;
                padding: 2rem 1rem;
                border: 1px solid #1F1F1F;
                border-top: unset;
                border-right: unset;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                gap: 3rem;
                .news-detail-links {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    gap: 2rem;
                    a {
                        margin: auto;
                        img {
                            width: 100%;
                        }
                    }
                }
                .news-detail-desc {
                    min-height: 200px;
                    h4 {
                        color: #FFF;
                        font-family: Montserrat;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 0.5rem;
                    }
                    p {
                        color: #A1A1A1;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .tokenomics-section {
        .tokenomics-info-header {
            border: 1px solid #1F1F1F;
            background: #0A0A0A;
            border-top: unset;
            padding: 2rem 1rem 1rem;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            justify-content: space-between;
            .tokenomics-desc {
                a {
                    width: 100%;
                }
                p {
                    font-size: 3.4vw !important;
                    font-weight: 300;
                    overflow: hidden;
                }
                b {
                    font-size: 3.4vw !important;
                    font-weight: 500;
                }
                text-align:center;
            }
            .token-buttons {
                display: flex;
                flex-flow: row;
                justify-content: center;
                gap: 1rem;
                margin: auto 0px;
                .button-small {
                    width: 100%;
                }
            }
        }
        .tokenomics-info-graphic {
            border: 1px solid #1F1F1F;
            border-top: unset;
            .tokenomics-header {
                padding: 2rem;
                p {
                    color: #FFF;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    width: auto;
                    margin: auto;
                }
                b {
                    font-weight: 500;
                }
            }
            .tokenomics-graphic {
                display: flex;
                flex-flow: row;
                width: 100%;
                justify-content: center;
                overflow: hidden;
                position: relative;
                img {
                    margin: auto;
                    margin-bottom: 0rem;
                }
            }
        }
    }
    footer {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 2rem 0rem;
        p {
            color: #FFF;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 166.667% */
        }
        .social-row {
            display: flex;
            flex-flow: row;
            gap: 1rem;
        }
    }
}
</style>
  